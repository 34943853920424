import React from 'react';
import PropTypes from 'prop-types';
import SearchPage from '../components/Views/SearchPage';
import { getDataForTransformationsQuery } from '../queries';
import {
  SearchItemsTitleService,
  SearchItemsH1Service,
  fromUrlToObj,
  getValueFromObject,
  getSeoData
} from '../utils/helpers';
import i18n from '../utils/i18n';
import { setChosenLocationNames, setSearchSort } from '../actions';

const Search = ({ transformedObj, seoData }) => {
  return <SearchPage transformedObj={transformedObj} h1={seoData.h1} />;
};

Search.getInitialProps = async ({
  apolloClient,
  asPath,
  query,
  store,
  res,
  req
}) => {
  const dataForTransform = await apolloClient.query({
    query: getDataForTransformationsQuery
  });
  const transformedObj = fromUrlToObj(dataForTransform, query);

  if (res && transformedObj.noMatch) {
    res.statusCode = 404;

    return {
      statusCode: 404
    };
  }

  const seoData = await getSeoData(apolloClient, asPath, req);
  const locationsIds = transformedObj.filter.locationIds || [];

  store.dispatch(
    setSearchSort(
      getValueFromObject(
        dataForTransform.data.sort,
        transformedObj.sorting,
        'id'
      ) || '1'
    )
  );

  store.dispatch(
    setChosenLocationNames(
      dataForTransform.data.locations
        .filter(location => locationsIds.includes(location.id))
        .map(location => location.name.trim())
    )
  );

  if (!seoData.title || !seoData.description) {
    seoData.title = new SearchItemsTitleService(
      dataForTransform.data,
      transformedObj.filter,
      i18n
    ).getTitle();

    seoData.description = i18n.t('default_search_description');
  }

  if (!seoData.h1) {
    seoData.h1 = new SearchItemsH1Service(
      dataForTransform.data,
      transformedObj.filter,
      i18n
    ).getH1();
  }

  return {
    transformedObj,
    seoData
  };
};

Search.propTypes = {
  transformedObj: PropTypes.object,
  seoData: PropTypes.object
};

export default Search;
